import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "mb-5 mb-xl-8 card" }
const _hoisted_2 = { class: "card-body py-3" }
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_5 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-6"
}
const _hoisted_6 = { class: "badge-light badge text-primary" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-6"
}
const _hoisted_9 = {
  class: "modal fade",
  tabindex: "-1",
  id: "kt_modal_1"
}
const _hoisted_10 = { class: "modal-dialog" }
const _hoisted_11 = { class: "modal-content" }
const _hoisted_12 = { class: "modal-body" }
const _hoisted_13 = { class: "modal-footer" }
const _hoisted_14 = {
  type: "button",
  class: "btn btn-light",
  "data-bs-dismiss": "modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_upload_filled = _resolveComponent("upload-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"card-header border-0 pt-5\"><h3 class=\"card-title align-items-start flex-column\"><span class=\"card-label fw-bolder fs-3 mb-1\">Résultats périmètre</span></h3><div class=\"align-items-end flex-column\"><a class=\"btn btn-primary btn-light btn-active-light-primary\" data-bs-toggle=\"modal\" data-bs-target=\"#kt_modal_1\"> Uploader les résulats </a></div></div>", 1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("table", _hoisted_4, [
            _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
              _createElementVNode("tr", { class: "fw-bolder text-muted" }, [
                _createElementVNode("th", { class: "min-w-150px" }, "Nom du fichier"),
                _createElementVNode("th", { class: "min-w-150px" }, "Fichier"),
                _createElementVNode("th", { class: "min-w-150px" }, "Campagne")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: result.pk
                }, [
                  _createElementVNode("td", null, [
                    _createElementVNode("a", _hoisted_5, _toDisplayString(result.name), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(result.tag.label), 1)
                  ]),
                  (result.campaign)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
                        _createElementVNode("a", _hoisted_8, _toDisplayString(result.campaign.label), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("h5", { class: "modal-title" }, "Uploader les résulats"),
            _createElementVNode("div", {
              class: "btn btn-icon btn-sm btn-active-light-primary ms-2",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, [
              _createElementVNode("span", { class: "svg-icon svg-icon-2x" })
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_el_upload, {
              class: "upload-demo w-100",
              drag: "",
              action: this.uploadUrl,
              headers: this.headers,
              multiple: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_upload_filled)
                  ]),
                  _: 1
                }),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "el-upload__text" }, "Déposer vos fichiers ici", -1))
              ]),
              _: 1
            }, 8, ["action", "headers"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("button", _hoisted_14, _toDisplayString(_ctx.$t("general.close")), 1)
          ])
        ])
      ])
    ])
  ], 64))
}